import { Paragraph, SectionTitle, TitleDescription } from '../Typography';
import { Section, TwoColumns } from '../Section';

import { Button } from '../Button';
import ModalForm from 'src/components/Modal/ModalFormSyllabus';
import Moon from 'src/components/Illustrations/Moon.svg';
import React from 'react';
/* --- import external libs ----*/
import { RoughNotation } from 'react-rough-notation';
import { SectionHeaderBlock } from 'src/components/Section/elements';
import { StaticImage } from 'gatsby-plugin-image';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';
import sizes from 'src/style/sizes';

const ProjectsOverview = React.memo(
  ({
    // isModalFormOpen,
    // setIsModalFormOpen,
    bootcampType,
    formSuccess,
    setFormSuccess,
    values,
    setValues,
    hideProjects = false,
    hideStudentsWork = false,
  }) => {
    const [isModalFormOpen, setIsModalFormOpen] = React.useState(false);

    return (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            height: 600,
            top: -300,
            width: '30vw',
            left: '-18%',
            zIndex: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [mediaQueries.tabletLandscapeUp]: {
              left: '-15%',
            },
          }}
        >
          <img src={Moon} alt="illustration lune" css={{ width: '100%' }} />
        </div>

        <Section theme="primary">
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <Sensor>
            {({ isVisible }) => ( */}
            <SectionHeaderBlock>
              <SectionTitle isMobileCenter>
                Une formation
                <span css={{ color: colors.yellow }}>
                  <br
                    css={{
                      display: 'none',
                      [mediaQueries.phoneOnly]: {
                        display: 'block',
                      },
                    }}
                  />
                  &nbsp;orientée projets
                </span>
              </SectionTitle>
              <Paragraph>
                Au Reacteur, nous accordons une place importante à la pratique. Tout au long de la
                formation, nos élèves réalisent une quantité importante de{' '}
                <span css={{ position: 'relative' }}>
                  <RoughNotation
                    type="underline"
                    multiline={true}
                    strokeWidth={2}
                    color={colors.yellow}
                    show={true}
                    padding={[0, 0, 4, 0]}
                  >
                    projets issus de cas réels
                  </RoughNotation>
                </span>
                , afin de les projeter dès le départ au plus proche du monde professionnel.
              </Paragraph>
            </SectionHeaderBlock>
            {/* )}
          </Sensor> */}
          </div>

          <TwoColumns
            sizeLeft={
              bootcampType === 'full-stack' ? sizes.columnWidth.large : sizes.columnWidth.medium
            }
            left={
              bootcampType === 'Temps Partiel' ? (
                <StaticImage
                  src="../../../src/data/images/2023_students10.jpg"
                  alt="Projets au Reacteur, la formation développeur Web et Mobile"
                  // placeholder="none"
                  css={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    borderRadius: 5,
                    boxShadow: '5px 25px 40px rgba(0, 0, 0, 0.2)',
                    [mediaQueries.phoneOnly]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletPortraitUp]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletLandscapeUp]: {
                      height: '100%',
                    },
                  }}
                />
              ) : (
                <StaticImage
                  src="../../../src/data/images/2023_studentsAndTeacher3.jpg"
                  alt="Projets au Reacteur, la formation développeur Web et Mobile"
                  // placeholder="none"
                  css={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    borderRadius: 5,
                    boxShadow: '5px 25px 40px rgba(0, 0, 0, 0.2)',
                    [mediaQueries.phoneOnly]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletPortraitUp]: {
                      height: '70%',
                    },
                    [mediaQueries.tabletLandscapeUp]: {
                      height: '100%',
                    },
                  }}
                />
              )
            }
            sizeRight={
              bootcampType === 'full-stack' ? sizes.columnWidth.small : sizes.columnWidth.medium
            }
            right={
              // <Sensor>
              //   {({ isVisibleTwo }) => (
              <div
                css={{
                  paddingTop: 20,
                  [mediaQueries.tabletLandscapeUp]: {
                    paddingTop: 0,
                  },
                }}
              >
                <TitleDescription>Apprendre à reproduire</TitleDescription>
                <Paragraph css={{ paddingTop: 8, paddingBottom: 40 }}>
                  Nous basons l'essentiel de l'apprentissage sur la reproduction de sites Web et
                  d'applications Mobile réels, afin d'aborder tous les concepts importants :
                  authentification des utilisateurs, paiement en ligne, upload de contenus,
                  géolocalisation, etc.
                </Paragraph>
                {hideProjects === false && (
                  <>
                    <TitleDescription>Travaillez avec des startupers</TitleDescription>
                    <Paragraph css={{ paddingTop: 8 }}>
                      En fin de bootcamp, vous pourrez participer à la réalisation d'un MVP (Minimum
                      Viable Product){' '}
                      <span css={{ position: 'relative' }}>
                        <RoughNotation
                          type="underline"
                          multiline={true}
                          strokeWidth={2}
                          color={colors.yellow}
                          show={true}
                          padding={[0, 0, 4, 0]}
                        >
                          pour une vraie startup
                        </RoughNotation>
                      </span>
                      . Nous effectuons un sourcing auprès de plusieurs incubateurs et
                      accélérateurs, pour vous amener des projets solides à réaliser. Cette démarche
                      permet à nos étudiants d'obtenir une vraie référence professionnelle.
                    </Paragraph>
                  </>
                )}
                {hideStudentsWork === false && (
                  <>
                    <TitleDescription>Travaillez entre élèves</TitleDescription>
                    <Paragraph css={{ paddingTop: 8 }}>
                      La formation à temps partiel étant longue, nous encourageons nos élèves à
                      travailler ensemble sur des projets de leur choix, pendant leur temps libre.
                      C'est l'occasion de créer des projets différents de ceux qui doivent être
                      réalisés en cours, et ainsi ajouter de nouvelles références à votre portfolio.
                    </Paragraph>
                  </>
                )}
                {hideProjects === true && hideStudentsWork === true ? (
                  <StaticImage
                    src="../../../src/data/images/project_screens2.png"
                    alt="Projets au Reacteur, la formation développeur Web et Mobile"
                    placeholder="none"
                    css={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: 'auto',
                      // borderRadius: 5,
                      // boxShadow: '5px 25px 40px rgba(0, 0, 0, 0.2)',
                      [mediaQueries.phoneOnly]: {
                        height: '70%',
                      },
                      [mediaQueries.tabletPortraitUp]: {
                        height: '70%',
                      },
                      [mediaQueries.tabletLandscapeUp]: {
                        height: '100%',
                      },
                    }}
                  />
                ) : null}
              </div>
              //   )}
              // </Sensor>
            }
          />
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 25,
              [mediaQueries.tabletLandscapeUp]: {
                marginTop: 50,
              },
            }}
          >
            {/* <AnchorButton to="/bootcamp-javascript/formation-developpeur-web-mobile/">
            Découvrez notre programme
          </AnchorButton> */}
            {bootcampType === 'full-stack' ? null : (
              <div css={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  css={{
                    minWidth: 210,
                    /* marginRight: 20, */
                  }}
                  onClick={() => {
                    setIsModalFormOpen(!isModalFormOpen);
                  }}
                >
                  Recevoir le programme de formation
                </Button>
                {isModalFormOpen && (
                  <>
                    <ModalForm
                      isOpen={isModalFormOpen}
                      onClose={() => {
                        setFormSuccess(false);
                        setIsModalFormOpen(false);
                      }}
                      fromFormationsPageType={bootcampType}
                      formSuccess={formSuccess}
                      setFormSuccess={setFormSuccess}
                      values={values}
                      setValues={setValues}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </Section>
      </div>
    );
  }
);

export default ProjectsOverview;
